"use client";
import { datadogRum, DefaultPrivacyLevel } from "@datadog/browser-rum";
import { useReportWebVitals } from "next/web-vitals";
import type { NextWebVitalsMetric } from "next/app";
import { useEffect } from "react";

import { isProduction } from "./utils/environment";

const shouldTrackToDatadog = isProduction;

const vitalsMapper: { [key: string]: string } = {
  // Length of time it takes for the page to start and finish hydrating (in ms)
  "Next.js-hydration": "nextJsHydration",
  // Length of time it takes for a page to start rendering after a route change (in ms)
  "Next.js-route-change-to-render": "nextJsRouteChangeToRender",
  // Length of time it takes for a page to finish render after a route change (in ms)
  "Next.js-render": "nextJsRender",

  // Web Vitals - https://web.dev/vitals/
  CLS: "cumulativeLayoutShift",
  FCP: "firstContentfulPaint",
  FID: "firstInputDelay",
  LCP: "largestContentfulPaint",
  TTFB: "timeToFirstByte",
};

export function DatadogInit() {
  useReportWebVitals((metric: NextWebVitalsMetric) => {
    const mappedName = vitalsMapper[metric.name] || metric.name;

    if (!shouldTrackToDatadog) {
      // eslint-disable-next-line no-console
      console.info(
        `Datadog RUM: would have tracked web vital ${mappedName} - ${metric.value}.`,
      );
      return;
    }

    datadogRum.addAction(`Web Vital: ${mappedName}`, {
      [mappedName]: metric.value,
    });
  });
  useEffect(() => {
    const applicationId = process.env.NEXT_PUBLIC_DATADOG_APP_ID;
    const clientToken = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN;
    if (applicationId && clientToken) {
      // See https://docs.datadoghq.com/real_user_monitoring/installation/?tab=us#initialization-parameters
      // for more information on what these individual parameters do.
      return datadogRum.init({
        applicationId,
        clientToken,
        traceSampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        service: "canvas",
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        defaultPrivacyLevel: DefaultPrivacyLevel.ALLOW,
        version: process.env.NEXT_PUBLIC_COMMIT_SHA,
        // Enables distributed tracing for any `*.zapier.com` APIs.
        // See: https://docs.datadoghq.com/real_user_monitoring/connect_rum_and_traces/?tab=browserrum
        allowedTracingUrls: [/https:\/\/(.*\.)?zapier\.com/],
        env: process.env.VERCEL_ENV || process.env.NODE_ENV,
        enableExperimentalFeatures: ["feature_flags"], // Collect feature flag data https://docs.datadoghq.com/real_user_monitoring/guide/setup-feature-flag-data-collection/?tab=browser
      });
    }
    if (!applicationId) {
      // eslint-disable-next-line no-console
      console.info(
        "Datadog RUM not initialized: missing applicationId or clientToken.",
      );
    }

    if (!shouldTrackToDatadog) {
      // eslint-disable-next-line no-console
      console.info(
        "Datadog RUM not initialized: not in a production environment.",
      );
    }
  }, []);
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}

export function addError(error: unknown, context?: object | undefined) {
  datadogRum.addError(error, context);
}
